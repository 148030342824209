hr.homeMarker {
  border: none;
  margin: 0;
  position: relative;
  top: -100px;
}

hr.featuresMarker {
  border: none;
  margin: 0;
  position: relative;
  top: -1.5vh;
}

hr.pricingMarker {
  border: none;
  margin: 0;
  position: relative;
  top: -0.75vh;
}

hr.contactMarker {
  border: none;
  margin: 0;
  position: relative;
  top: -11vh;
}

@media only screen and (max-width: 480px) {
  hr.homeMarker {
    top: -80px;
  }

  hr.featuresMarker {
    top: -6.5vh;
  }

  hr.pricingMarker {
    top: -5vh;
  }

  hr.contactMarker {
    top: -3.5vh;
  }
}
