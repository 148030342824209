.app {
  min-height: 100vh;
  overflow-x: hidden;
}

.app.noScroll {
  overflow: hidden;
}

.staticBG {
  background-color: #d22c40;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  animation: fade 1s forwards;
}

.webp {
  background: url('../../assets/images/global/RRC-static-bg-dt.webp')
    center/cover no-repeat border-box border-box;
}

.noWebp {
  background: url('../../assets/images/global/RRC+TL+BG-1920x1080-COMP90.jpg')
    center/cover no-repeat border-box border-box;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
