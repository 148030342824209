h4.message {
  color: #59d9be;
}

h4.error {
  color: #ef3444;
}

form > label {
  display: flex;
}

form > label > span,
form p {
  align-self: center;
  color: #b6b6b6;
  font-size: 0.9375rem;
}

form > label > * {
  margin: 0.5rem 0;
}

label.check {
  cursor: pointer;
}

form > label > input:not([type='checkbox']) {
  font-size: 1rem;
  font-style: italic;
  color: white;
  padding: 0 0.625rem;
  background-color: #313438;
  border: none;
  height: 2.5rem;
  width: 100%;
}

form > label > [type='checkbox'] {
  cursor: pointer;
  margin: 1.01rem 0.75rem 1rem 0.25rem;
  transform: scale(1.25);
}

button.submit {
  text-shadow: 0 0 0.5rem rgba(26, 148, 119, 0.7);
  transition: all 0.3s ease-in-out;
  background-color: #28cead;
  width: 100%;
}

button.submit:hover {
  background-color: #4cd9be;
}
