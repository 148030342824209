/* CSS VARIABLES */

:root .cog-cognito {
  --background-hsl: 0, 0%, 80%;
  --border-radius: 3px;
  --border-width: 1px;
  --color: #444444;
  --font-family: Poppins, Arial, Helvetica, sans-serif;
  --font-size: 1rem;
  --font-weight: normal;
  --gutter: 25px;
  --highlight: #28cead;
  --highlight-reverse: hsl(0, 0%, 100%);
  --icon-weight: 0.9;
  --line-height: normal;
  --negative: #ef3444;
  --negative-reverse: white;
  --primary: #28cead;
  --primary-reverse: #ffffff;
  --small-text: 1rem;
  --speed: 1s;
  --a__color: #28cead;
  --a__font-family: Poppins, Arial, Helvetica, sans-serif;
  --a__font-size: 0.88rem;
  --a__font-weight: normal;
  --checkable__border-color: #bbb;
  --checkbox__border-radius: calc(var(--input__border-radius) * 0.2);
  --checkable__scale: 1;
  --checkable-checked__scale: 1.1;
  --button-primary__background-color: #28cead;
  --button-primary__border-color: #28cead;
  --button-primary__border-width: var(--border-width);
  --button-primary__border-radius: calc(var(--border-radius) + 47px);
  --button-primary__color: #ffffff;
  --button-primary__font-family: Poppins, Arial, Helvetica, sans-serif;
  --button-primary__font-size: 1rem;
  --button-primary__font-weight: normal;
  --button-secondary__background-color: #ffffff;
  --button-secondary__border-color: #28cead;
  --button-secondary__border-radius: calc(var(--border-radius) + 47px);
  --button-secondary__border-width: var(--border-width);
  --button-secondary__color: #28cead;
  --button-secondary__font-family: Poppins, Arial, Helvetica, sans-serif;
  --button-secondary__font-size: 1rem;
  --button-secondary__font-weight: normal;
  --form__background-color: transparent;
  --form__margins: 0px;
  --form__margins--responsive: 0px;
  --form__opacity: 1;
  --form__width: 960px;
  --h2__font-size: 2rem;
  --header__background-color: rgba(0, 0, 0, 0);
  --header__padding-bottom: 0;
  --header__color: #000000;
  --header__font-family: Poppins, Arial, Helvetica, sans-serif;
  --header__font-size: 3.125rem;
  --header__font-weight: bold;
  --heading__base-size: 1.25em;
  --heading__color: #000000;
  --heading__font-family: Poppins, Arial, Helvetica, sans-serif;
  --heading__font-size: 1.5rem;
  --heading__font-weight: bold;
  --input__background-color: white;
  --input__border-color: #ccc;
  --input__border-radius: calc(var(--border-radius) * 0.75);
  --input__border-style: solid;
  --input__border-width: var(--border-width);
  --input__box-shadow: none;
  --input__color: #333;
  --input__line-height: calc(var(--line-height) - 0.1);
  --input__padding-h: 0.75rem;
  --input__padding-v: 2rem;
  --input-focus__box-shadow: 0 0 1px 2pxvar (--input__background-color);
  --label__color: #000000;
  --label__font-family: Poppins, Arial, Helvetica, sans-serif;
  --label__font-size: 1.125rem;
  --label__font-weight: bold;
  --page__background-color: #f8f8f8;
  --page__background-image: none;
  --page__background-size: cover;
  --page__margins: 40px auto auto;
  --placeholder__color: #cccccc;
  --toggle__border-radius: var(--input__border-radius);
  --input-highlight: var(--highlight);
  --input-highlight-reverse: transparent;
  --input__padding-v: 0.75rem;
  --input__padding-h: 2rem;
  --button--padding-v: 0.75rem;
  --button--padding-h: 2rem;
  --input__border-color: transparent;
  --input__box-shadow: 0 0 5pxHSLA (var(--background-hsl), 0.7),
    0 1px 3px HSLA(var(--background-hsl), 0.2), 0 1px 3px HSLA(0, 0%, 0%, 0.1);
  --checkable__scale: 1.1;
  --checkable__border-color: HSLA(var(--background-hsl), 0.8);
}

/* FONTS */

#root
  > div
  > main
  > div
  > section
  > div
  > div
  > div
  > form
  > div
  > div
  > div.cog-body
  > div
  > div.cog-row
  > section
  > div
  > div
  > section
  > div
  > h3 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

/* GENERAL */

#root > div > main > div > section > div > div > div > form {
  width: 960px;
}

#root .cog-label {
  padding-bottom: 0.75rem;
}

#root
  div.cog-body
  div.cog-choice
  div.cog-checkable.cog-input.cog-checkable--inline
  label.el-radio
  span.el-radio__label {
  display: inline;
  padding: 1.75rem 1.25rem 1.75rem 1.75rem;
}

#root
  > div
  > main
  > div
  > section
  > div
  > div
  > div
  > form
  > div
  > div
  > div.cog-body
  > div
  > div:nth-child(3)
  > fieldset
  > div.cog-choice
  > div.cog-checkable.cog-input.cog-checkable--inline
  > div
  > label
  > span.el-checkbox__input {
  margin-top: 4px;
}

/* RESPONSIVE */

@media only screen and (max-width: 1200px) {
  #root > div > main > div > section > div > div > div > form {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  #root
    > div
    > main
    > div
    > section
    > div
    > div
    > div
    > form
    > div
    > div
    > div.cog-body {
    margin-top: 1rem;
  }

  #root
    > div
    > main
    > div
    > section
    > div
    > div
    > div
    > form
    > div
    > div
    > header
    > div
    > h1 {
    margin-top: 1.125rem;
    font-size: 2.125rem;
  }

  #root
    > div
    > main
    > div
    > section
    > div
    > div
    > div
    > form
    > div
    > div
    > div.cog-body
    > div
    > div:nth-child(1)
    > section
    > div
    > h2 {
    font-size: 1.75rem;
  }

  #root
    > div
    > main
    > div
    > section
    > div
    > div
    > div
    > form
    > div
    > div
    > div.cog-body
    > div
    > div:nth-child(1)
    > section
    > div
    > div
    > section
    > div
    > h3 {
    font-size: 1.25rem;
    padding-bottom: 2rem;
  }

  #root
    > div
    > main
    > div
    > section
    > div
    > div
    > div
    > form
    > div
    > div
    > div.cog-body
    > div
    > div.cog-row
    > section
    > div
    > div
    p {
    font-size: 1rem;
  }

  #root
    > div
    > main
    > div
    > section
    > div
    > div
    > div
    > form
    > div
    > div
    > div.cog-page-progress.cog-page-progress--compact.cog-page-progress--bar {
    display: none;
  }

  #root .cog-label {
    font-size: 1.125rem;
    margin-top: 1rem;
    padding-bottom: 0.75rem;
  }
}
