.overlay {
  touch-action: none;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  height: 100vh;
  opacity: 0;
  visibility: hidden;
}

.fadeIn {
  opacity: 1;
  visibility: visible;
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
}

.drawer {
  touch-action: none;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  position: fixed;
  top: -86vh;
  left: 0;
  width: 100vw;
  background-color: #2c2c2c;
  height: 86vh;
  overflow: auto;
}

.drawer.open {
  transform: translateY(86vh);
}

.drawer a {
  text-decoration: inherit;
  color: inherit;
}

.drawer li svg,
.drawer p svg {
  color: #28cead;
  margin-right: 0.55rem;
}

div.row {
  padding: 80px 30px;
  color: white;
}

div.left,
div.right {
  padding: 0 40px;
}

div.right {
  justify-content: space-between;
}

.navList li {
  letter-spacing: 0.03em;
  transition: transform 0.3s ease-out;
  font-size: 1.5625rem;
  font-weight: bold;
  border-bottom: 1px solid #3a3c3f;
}

.navList li:hover {
  transform: translate(0.5rem, 0);
  color: #d4d4d4;
}

.navList li:last-child {
  color: #28cead;
}

.navList li:last-child:hover a {
  color: #25c39d;
}

.navList li a {
  display: block;
  padding: 1.875rem 0;
  width: 100%;
}

.navList li a svg {
  color: inherit;
}

.recentPosts {
  color: white;
  margin-bottom: 0;
  transition: transform 0.3s ease-out;
  cursor: pointer;
}

.recentPosts:hover {
  transform: translate(0.5rem, 0);
  color: #d4d4d4;
}

.socialLink {
  padding-bottom: 2rem;
  border-bottom: 1px solid #3a3c3f;
}

.linkWrapper {
  display: flex;
}

.socialLink > .linkWrapper > button {
  display: flex;
  margin: 0.7rem 1rem 0.3rem 0;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1.25rem;
  background-color: transparent;
  border: 3px solid #28cead;
  border-radius: 100%;
  color: #28cead;
}

.socialLink > .linkWrapper > button:hover {
  color: #7ae6d1;
  border-color: #7ae6d1;
  background-color: #1a9477;
  transform: scale(1.1);
}

.socialLink > .linkWrapper > button > svg {
  align-self: center;
}

@media only screen and (max-width: 1024px) {
  div.drawer {
    height: fit-content;
    top: -110vh;
  }

  .drawer.open {
    transform: translateY(110vh);
  }

  div.row {
    padding: 6vh 1.875rem 8.5vh;
  }
}

@media only screen and (max-height: 800px) and (orientation: landscape) {
  .navList li a {
    font-size: 1.25rem;
    padding: 1.25rem 0;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  div.drawer {
    top: -120vh;
  }

  .drawer.open {
    transform: translateY(120vh);
  }
}

@media only screen and (max-width: 768px) {
  div.row {
    padding: 8vh 1rem 12vh 0;
  }

  div.colWrapper {
    flex-direction: column;
  }

  div.colWrapper > * {
    width: 75%;
    margin: 0 auto;
  }

  .navList li a {
    font-size: 1.125rem;
    padding: 1.25rem 0;
  }

  div.socialLink {
    padding: 0.75rem 0 1.25rem;
  }

  div.socialLink > div.linkWrapper > button {
    margin: 0.25rem 0.75rem 0.25rem 0;
  }

  form.subForm {
    display: none;
  }

  p.recentPosts {
    display: none;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .navList li a {
    font-size: 1rem;
    padding: 0.9rem 0;
  }
}

@media only screen and (max-width: 480px) {
  div.row {
    padding: 8vh 1rem 12vh;
  }
}

@media only screen and (max-height: 730px) {
  div.socialLink {
    display: none;
  }
}
