section.aboveFold {
  height: calc(100vh - 100px);
  position: relative;
}

div.left {
  text-align: left;
  padding: 6.75vh 2.5rem 7.5rem 9.5vw;
  color: white;
  justify-content: center;
}

div.left h1 {
  margin-bottom: 0;
}

div.right {
  padding: 5rem 6.5rem 6.5rem 1rem;
  justify-content: center;
}

.carousel {
  max-width: 40vw;
}

.carousel,
.carousel img {
  width: 100%;
  margin: 0 auto;
}

.carousel figure:focus {
  outline: none;
}

button.cta {
  font-weight: bold;
  padding: 1rem 2rem;
  margin: 0.75rem 0;
  align-self: flex-start;
  color: #cd2767;
  background-color: white;
}

button.cta:hover {
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.5);
  background-color: white;
}

svg.shape1 {
  position: absolute;
  width: calc(100% + 40px);
  left: -20px;
  bottom: -24px;
}

section.aboveFold svg.shape1 path {
  fill: white;
}

@media only screen and (max-width: 1024px) {
  section.aboveFold svg.shape1 {
    width: calc(100% + 4px);
    left: -2px;
    bottom: -2px;
  }

  div.colWrapper {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 2.5rem;
  }

  div.left {
    text-align: center;
    padding: 0;
    position: relative;
    left: -0.25rem;
  }

  div.right {
    padding: 0 0 10vh 3.25vw;
    height: 30vw;
  }

  .carousel {
    max-width: 60vw;
  }

  h3.tagline {
    margin: 0.25rem 0 0.625rem;
  }

  button.cta {
    align-self: center;
    padding: 0.75rem 1.5rem;
    margin: 0.25rem;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  section.aboveFold {
    height: calc(100vw - 100px);
  }
}

@media only screen and (max-width: 480px) {
  section.aboveFold {
    display: flex;
    align-items: center;
  }

  div.colWrapper {
    margin: 2vh auto 13vh;
    justify-content: flex-end;
    padding: 0 1rem;
    height: auto;
  }

  div.left {
    padding: 0 1vw 0 0;
    left: 0;
  }

  div.right {
    padding: 6vh 5.5vw 2.75vh 9.5vw;
    height: 30vh;
  }

  .carousel {
    max-width: 75vw;
  }
}
