svg.footerShape1 path {
  fill: #2c2c2c;
}

svg.footerShape1 {
  fill: #2c2c2c;
  position: relative;
  width: calc(100% + 40px);
  left: -20px;
  bottom: -0.75vw;
}

section.footer {
  position: relative;
  background-color: #2c2c2c;
  padding: 2.375rem 7vw 3.625rem;
}

section.footer * {
  color: white;
}

section.footer h5 {
  margin: 0;
}

section.footer div[class*='col'] > * {
  align-self: center;
}

div.col1 img {
  align-self: center;
}

div.col2 {
  width: 120vw;
  padding: 0 1rem 0 2rem;
}

div.col3 {
  width: 120vw;
  padding: 0 2rem 0 1rem;
}

div.col4 button {
  margin-top: 0;
}

.footerLogo {
  width: 10rem;
}

.address span,
.supportLegal a {
  display: block;
  text-decoration: none;
}

.supportLegal a:hover {
  text-decoration: underline;
}

.contact span {
  display: block;
}

.spacer {
  border: none;
  margin: 0.25rem 0;
}

.copyright {
  border-top: 1px solid #444444;
  background-color: #2c2c2c;
  padding: 0.875rem;
}

.copyright p {
  color: grey;
  margin: 0;
  font-size: 0.875rem;
  text-align: center;
}

.copyright p a {
  color: inherit;
}

@media only screen and (max-width: 1250px) {
  section.footer {
    font-size: 0.75rem;
  }

  div.col2 {
    padding: 0 0.5rem 0 1rem;
  }

  div.col3 {
    padding: 0 1rem 0 0.5rem;
  }

  div.col4 button {
    padding: 0.75rem 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  svg.footerShape1 {
    width: calc(100% + 4px);
    left: -2px;
    bottom: -1vw;
  }

  section.footer {
    font-size: 0.625rem;
  }

  div.col1 img {
    width: 7.625rem;
  }
}

@media only screen and (max-width: 768px) {
  svg.footerShape1 {
    bottom: -1.75vw;
  }

  section.footer {
    padding: 3.75rem 1.5rem 2rem;
    text-align: center;
    font-size: 0.875rem;
  }

  section.footer h5 {
    margin-top: 1.25rem;
  }

  section.footer div[class*='col'] {
    padding: 0;
  }

  div.wrapper {
    flex-direction: column;
  }

  div.col1 img {
    width: 9rem;
    margin: 0.125rem auto;
  }

  div.col2,
  div.col3 {
    width: unset;
  }

  div.col4 button {
    margin-top: 0.25rem;
  }

  section.footer div.col4 {
    padding-top: 2rem;
  }

  .copyright p {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 480px) {
  svg.footerShape1 {
    bottom: -2.5vw;
  }
}
