.hamburger {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  height: 52px;
  width: 52px;
  padding: 17px 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger * {
  width: 30px;
  border-top: 2px solid white;
}

.close {
  animation: unsetFlex 0.02s linear forwards;
}

.close .topSlice {
  transform: rotate(-45deg) translate(-1.5px, 2px);
  border-width: 3px;
}

.close .middleSlice {
  animation: spinAndFadeOut 0.5s ease-in-out forwards;
}

.open .middleSlice {
  animation: spinAndFadeIn 0.33s ease-in-out forwards;
}

.close .bottomSlice {
  transform: rotate(45deg) translate(-1.5px, -2px);
  border-width: 3px;
}

@keyframes unsetFlex {
  to {
    display: unset;
    padding: 22px 11px;
  }
}

@keyframes spinAndFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
    transform: rotate(360deg);
  }
}

@keyframes spinAndFadeIn {
  from {
    opacity: 0;
    display: none;
    transform: rotate(360deg);
  }
  to {
    opacity: 1;
  }
}
